import Link from 'next/link';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Footer from 'components/Footer';
import Pagination from 'components/Pagination';
import PoolCard from 'components/PoolCard';
import { useLoader } from 'contexts/Loader';
import { api } from 'services/apiClient';
import { Pool } from 'templates/pool/types';
import FaqSection2 from 'views/PricingPage/FaqSection2';
import HeroThree from './Hero/HeroThree';
import HeroTwo from './Hero/HeroTwo';
import FilterPoolCard from 'components/FilterPoolCard';
import { media } from 'utils/media';
import Header from './Header';
import FooterScrool from 'components/FooterScrool';
import Pig from 'components/Pig';
import { useAuth } from 'contexts/AuthContext';

type PoolFilter = {
  period?: string;
  categoryId?: string;
  state?: string;
};

export type Paginate<T> = {
  pages: number;
  total: number;
  values: T[];
};

const useHomePageDefaultState = () => {
  const [filter, setFilter] = useState<PoolFilter>();
  const [pools, setPools] = useState<Pool[]>([]);
  const { setLoader } = useLoader();

  const [totalPools, setTotalPools] = useState(0);
  const [totalPoolPages, setTotalPoolPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const getPoolsWithFilter = useCallback(
    (page: number) => {
      setLoader?.(true);
      api
        .post<Paginate<Pool>>(
          `/pools/_search/?limit=3&page=${page}`,
          filter
            ? {
                category_id: filter.categoryId,
                state: filter.state,
                period: filter.period,
              }
            : undefined,
        )
        .then(({ data }) => {
          setPools(data.values ?? []);
          setTotalPools(data.total ?? 0);
          setCurrentPage(page);
          setTotalPoolPages(data.pages);
        })
        .finally(() => {
          setLoader?.(false);
        });
    },
    [setLoader, filter],
  );

  useEffect(() => {
    getPoolsWithFilter(currentPage);
  }, [currentPage, getPoolsWithFilter]);

  const handleCleanFilter = useCallback(() => {
    setFilter(undefined);
  }, []);

  const handleNextPage = useCallback(() => {
    const next = currentPage + 1;
    if (totalPools >= next) getPoolsWithFilter(next);
  }, [currentPage, getPoolsWithFilter, totalPools]);

  const handlePreviewPage = useCallback(() => {
    const preview = currentPage - 1;
    if (totalPools > preview) getPoolsWithFilter(preview);
  }, [currentPage, getPoolsWithFilter, totalPools]);

  const handleOnFilter = useCallback(
    (filter: PoolFilter) => {
      setFilter({ ...filter });
      getPoolsWithFilter(1);
    },
    [getPoolsWithFilter],
  );

  return {
    totalPoolPages,
    totalPools,
    pools,
    currentPage,
    getPoolsWithFilter,
    handleCleanFilter,
    handleNextPage,
    handlePreviewPage,
    handleOnFilter,
  };
};

export default function HomePageDefault() {
  const { totalPoolPages, currentPage, pools, handleNextPage, handlePreviewPage, handleOnFilter, getPoolsWithFilter } =
    useHomePageDefaultState();
  const { user } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(!!user?.email);
  }, [isAuthenticated, user]);

  return (
    <MainContainer>
      <Header />
      <HeroOne>
        <div>
          <Image src="/especial.svg" />
          <ImageMobile src="/especialMobile2.png" alt="Mobile Image" />
        </div>
      </HeroOne>

      {/*
        <Title>
          <div>
            <img src="logo.svg" alt="" />
          </div>
        Nossa vaquinha
          <div>
            <a href="/login">Quero criar uma campanha</a>
          </div>
              
        </Title>
        */}

      <Animation>
        <div>
          <Pig />
        </div>
      </Animation>

      <FooterScrool />

      <SectionTree>
        <div>
          <br />
          <h1>Você não está sozinho!</h1>
          <br />
          <br />
          <br />
        </div>
        <CardContainerSectionTree>
          <CardTree>
            <Img>
              <img src="/heart.svg" alt="" />
              <h1>
                Criar campanha <br /> Grátis
              </h1>
            </Img>
            <br />
            <h3>Plataforma segura</h3>
            <p>Suas doações são protegidas por tecnologia de segurança do Banco Stone.</p>
          </CardTree>

          <CardTree>
            <Img>
              <img src="/hands.svg" alt="" />
              <h1>
                Personalizar site <br />
                Grátis
              </h1>
            </Img>
            <br />
            <h3>Edição fácil e criativa</h3>
            <p>Ferramentas exclusivas para deixar o site da campanha do seu jeito.</p>
          </CardTree>

          <CardTree>
            <Img>
              <img src="/people.svg" alt="" />
              <h1>
                Taxa Nossa Vaquinha <br />
                0%
              </h1>
            </Img>
            <br />
            <h3>Mães não pagam!</h3>
            <p>Demais beneficiários pagam apenas R$ 1,00 por doação. Leia os Termos de Uso.</p>
          </CardTree>

          <CardTree>
            <Img>
              <img src="/hands2.svg" alt="" />
              <h1>
                Tarifa de saque <br />
                R$ 3,67
              </h1>
            </Img>
            <br />
            <h3>Pague uma única vez</h3>
            <p>Sugerimos aguardar receber todas as doações para pagar R$ 3,67 uma única vez.</p>
          </CardTree>
        </CardContainerSectionTree>
      </SectionTree>

      <ButtonSection>
        <Link href={!isAuthenticated ? '/login' : '/profile/user'} passHref>
          <ButtonCreate>
            Criar Vaquinha Grátis
          </ButtonCreate>
        </Link>
      </ButtonSection>


      {/*
        <SectionPromotion>
          <h1>Taxa <strong>Zero %</strong> para mães!</h1>

          <a href="/login">Quero criar uma campanha</a>

          <Promotion>
            <CardPromotion>
              <img src="/promotion1.png" alt="" />
              <h2>Criar campanha</h2>
              <h3>Plataforma segura</h3>
              <p>Suas doações são protegidas por tecnologia de segurança da Banco Stone.</p>
            </CardPromotion>

            <CardPromotion>
              <img src="/promotion2.png" alt="" />
              <h2>Personalizar site</h2>
              <h3>Edição fácil e criativa</h3>
              <p>Ferramentas exclusivas para deixar seu site da campanha do seu jeito.</p>
            </CardPromotion>

            <CardPromotion>
              <div>
                <img src="/promotion3.png" alt="" />
                <h2>Taxa Nossa Vaquinha</h2>
              </div>

              <h3>Mães e OSC não pagam!</h3>
              <p>Demais beneficiários pagam apenas R$ 1,00 por doação. Leia os Termos de Uso.</p>
            </CardPromotion>

            <CardPromotion>
              <img src="/promotion.png" alt="" />

              <h2>Tarifa de Saque</h2>

              <h3>Pague uma única vez</h3>
              <p>Sugerimos aguardar receber todas as doações para pagar R$ 3,67 uma única vez.</p>
            </CardPromotion>
        </Promotion>
      </SectionPromotion>
       */}

      <Line>
        <img src="./line.svg" alt="linhas" />

        <SecondImage src="./linha.png" alt="Segunda Imagem" />
      </Line>

      

      <div>
        <FilterPoolCard onSearch={handleOnFilter} />
      </div>

      <SectionPool>
        <PoolGrid>
          {pools.map((item, index) => (
            <div key={index}>
              <Link href={`/${item.slug}`}>
                <a href={`/${item.slug}`}>
                  <PoolCard data={item} />
                </a>
              </Link>
            </div>
          ))}
        </PoolGrid>
        <Pagination
          onPress={getPoolsWithFilter}
          currentPage={currentPage}
          totalPages={totalPoolPages}
          onNextPage={handleNextPage}
          onPreviewPage={handlePreviewPage}
        />
      </SectionPool>

      <SectionTwo>
        <h1>Como funciona</h1>

        <CardContainerTwo>
          <CardTwo>
            <img src="/1.svg" alt="" />
            <p>
              Crie sua conta gratuitamente. É bem simples e seguro: <a href="/login">clique aqui</a>
              <br></br>Mães e OSC são isentos de taxas percentuais. Os demais beneficiários (não mães) pagam apenas R$ 1,00 por doação.
            </p>
          </CardTwo>

          <CardTwo>
            <img src="/2.svg" alt="" />
            <p>
              Personalize grátis seu site de campanha com as cores que desejar e uma imagem de capa. Você possui também espaço para vídeo,
              álbum de fotos e um mural de mensagens de incentivo. 
            </p>
          </CardTwo>

          <CardTwo>
            <img src="/3.svg" alt="" />
            <p>
               Conte sua história e defina qual valor você precisa. Publique nas suas redes sociais e receba as doações com segurança em sua
              conta-corrente.  
            </p>
          </CardTwo>
        </CardContainerTwo>
      </SectionTwo>

      <ButtonSection>
        <Link href={!isAuthenticated ? '/login' : '/profile/user'} passHref>
          <ButtonCreate>
            Criar Campanha Grátis
          </ButtonCreate>
        </Link>
      </ButtonSection>

      <HeroThree />

      <Line>
        <img src="./line.svg" alt="linhas" />

        <SecondImage src="./linha.png" alt="Segunda Imagem" />
      </Line>

      <br /><br /><br /><br />
      <br /><br /><br /><br />

      {/*
      <HeroTwo />
      */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/*
      <LineTwo>
        <img src="./code.svg" alt="linhas" />
      </LineTwo>
      */}

      <CommonQuestions>
        <h2>Perguntas Frequentes</h2>
        <FaqSection2 />
      </CommonQuestions>

      <Footer />
    </MainContainer>
  );
}

const Animation = styled.div``;

const MainContainer = styled.main`
  font-display: swap;

  @media (max-width: 500px) {
    max-width: 100vw;
  }
`;

export const Circle = styled.span<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: ${({ background }) => background};
  border-radius: 50%;
  margin-top: -30px;
  margin-bottom: 10px;

  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 58px; /* 161.111% */
`;

const HeroOne = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    width: 100%;
    height: auto;
  }
`;

const PoolGrid = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 130em;
  margin: 54px auto;

  a,
  a:visited,
  a:active {
    text-decoration: none !important;
  }

  ${media('<=tablet')} {
    grid-template-columns: 1fr 1fr;
  }

  ${media('<=phone')} {
    grid-template-columns: 1fr;
  }
`;

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url('/pool/home-header-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 730px;
  margin-bottom: 69px;
  flex-direction: column;
  row-gap: 20px;

  margin-top: 4.5rem;

  & > div {
    :first-child {
      color: #174f68;
      text-align: center;
      font-family: 'Nunito';
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
    }
  }

  @media (max-width: 1090px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: -4.3rem;
  }
`;

const Content = styled.div`
  flex: 1;

  @media (min-width: 1068px) {
    margin-left: 150px;
  }

  @media (max-width: 768px) {
    padding: 3rem;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ImageMobile = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 8rem;
  color: #174f68;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 700px;
  }

  background-image: linear-gradient(to left, #ef4444, #174f68, #ef4444);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  div {
    margin-top: -5rem;
  }
  a {
    background: #174f68;
    font-size: 1.5rem;
    padding: 1.3rem;
    border-radius: 10px;
    text-decoration: none;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 4rem;

    img {
      width: 300px;
    }

    div {
      margin-top: 4px;
    }
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 15px;
`;

const Button = styled.button`
  padding: 12px 25px;
  background-color: #174f68;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  font-size: 15px;

  &:hover {
    opacity: 0.9;
  }
`;

const Sectiongreen = styled.div`
  width: 100%;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #174f68;
  flex-direction: column;

  h1 {
    color: #fff;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 6rem;
  }

  @media (max-width: 768px) {
    height: 150%;
    margin-top: 9rem;

    h1 {
      margin: 5rem;
    }
  }

  border-top-left-radius: 50% 7%;
  border-top-right-radius: 50% 7%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7rem;
  margin-bottom: 4rem;
  justify-content: center;
  align-items: center;
  max-width: 70%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    margin-bottom: 5rem;
  }
`;

const Card = styled.div`
  background: #fbf9ed;
  border-radius: 20px;
  height: 165px;
  width: 300px;
  flex-basis: calc(33.3% - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;

  p {
    font-size: 16px;
  }

  img {
    position: relative;
    left: 0;
    top: -30px;
    max-width: 50%;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-basis: 20%;
  }
`;

const SecondImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: 50px;
  left: 0;
  display: none;
`;

const Line = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: -300px;
    left: 0;
  }

  @media (max-width: 768px) {
    ${SecondImage} {
      top: 0px;
      display: block;
    }
    img {
      display: none;
    }
  }
`;

const CommonQuestions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fbd5d3;
  align-items: center;
  justify-content: center;
  padding: 150px 150px;
  position: relative;

  h2 {
    font-family: 'P22 Mackinac Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;

    @media (max-width: 478px) {
      padding: 20px;
      text-align: center;
    }
  }

  @media (max-width: 778px) {
    padding: 150px 10px;
  }
`;

const SectiongreenTwo = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #9edcf8;

  h1 {
    color: #fff;
    font-weight: 700;
    font-size: 4rem;
  }

  p {
    color: #fff;
  }

  button {
    margin-top: 30rem;
    background: #174f68;
    border: none;
    padding: 10px 20px;
    font-size: 1.5rem;
    color: #fff;
    border-radius: 10px;
  }
`;

const SectionPool = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: #174f68;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 2rem;
  }

  & > div {
    align-self: center;
    text-align: center;

    :first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 500px;

      & h1:first-child {
        color: #174f68;
        text-align: center;
        font-family: 'Nunito';
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        line-height: 40px;
      }
    }
  }
`;

const SectionTwo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fbf9ed;

  margin-bottom: 8rem;

  h1 {
    color: #174f68;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 6rem;
    margin-top: 8rem;
  }

  @media (max-width: 768px) {
    h1 {
      margin-bottom: 6rem;
    }

    margin-bottom: 9rem;
  }
`;

const CardContainerTwo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    height: 100%;
  }
`;

const CardTwo = styled.div`
  background: #fbf9ed;
  border-radius: 10px;
  height: 250px;
  width: 390px;
  flex-basis: calc(30% - 0.1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;

  h2 {
    font-size: 20px;
    margin-bottom: 1rem;
    color: #174f68;
  }

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  p {
    font-size: 18px;
    color: #656b8a;
  }

  img {
    position: relative;
    left: 0;
    top: -30px;
    max-width: 80%;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 300px;
    flex-basis: 10%;
  }
`;

const LineTwo = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0;

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: -100px;
    left: 0;
    z-index: 999999;
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

//-----------------

const SectionPromotion = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fbf9ed;

  margin-top: 5rem;

  @media (max-width: 768px) {
    margin-top: 200px;
  }

  margin-bottom: 20rem;

  a {
    color: #fff;
    background: #174f68;
    text-decoration: none;
    padding: 1rem;
    font-size: 18px;
    border-radius: 6px;

    margin-top: -4rem;
    margin-bottom: 4rem;

    transition: opacity 0.3s ease;
  }

  a:hover {
    opacity: 0.8;
  }

  h1 {
    color: #174f68;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 6rem;
    margin-top: 8rem;

    strong {
      color: #f19392;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
      margin-bottom: 6rem;
    }

    margin-bottom: 9rem;
  }
`;

const Promotion = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    height: 100%;
  }
`;

const CardPromotion = styled.div`
  background: #fbf9ed;
  border-radius: 10px;
  height: 380px;
  width: 350px;
  flex-basis: calc(20% - 0.1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  div {
    background: red;
    width: 100%;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 1rem;
    color: #174f68;
  }

  h3 {
    font-size: 15px;
    color: #174f68;
    margin: 10px;
  }

  :nth-child(1) {
    h2,
    p {
      color: #67bea4;
    }

    // box-shadow: 10px 10px 20px 10px rgba(105, 190, 164, 0.2);
  }

  :nth-child(2) {
    h2,
    p {
      color: #ffc100;
    }
    // box-shadow: 10px 2px 10px rgba(60, 146, 193, 0.2);
  }

  :nth-child(3) {
    h2,
    p {
      color: #f19392;
    }
    // box-shadow: 10px 2px 10px rgba(241, 147, 146, 0.2);
  }

  :nth-child(4) {
    h2,
    p {
      color: #3c92c1;
    }
    // box-shadow: 10px 2px 10px rgba(255, 193, 0, 0.2);
  }

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  p {
    font-size: 15px;
    color: #174f68;

    font-weight: 900;
  }

  img {
    position: relative;
    left: 0;
    top: 0;
    max-width: 80%;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-basis: 10%;
    width: 300px;
  }
`;

const SectionTree = styled.div`
  width: 100%;
  margin-top: 200px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fbf9ed;

  margin-bottom: 8rem;

  h3 {
    margin-top: 2rem;
    margin: 0.1rem;
    font-size: 2rem;
    color: hsl(210, 8%, 43.54%);
  }

  h1 {
    color: #174f68;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 2rem;
    margin-top: -6rem;
  }

  @media (max-width: 768px) {
    margin-top: 300px;

    h1 {
      margin-bottom: 6rem;
      font-size: 3rem;
    }

    margin-bottom: 9rem;
  }
`;

const CardContainerSectionTree = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 7rem;
    height: 100%;
  }
`;

const CardTree = styled.div`
  background: #fbf9ed;
  border-radius: 10px;
  height: 350px;
  width: 400px;
  flex-basis: calc(23% - 0.1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  p {
    margin-top: 1.5rem;
    font-size: 18px;
    color: #656b8a;
    width: 85%;
  }

  img {
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: auto;
  }

  @media (max-width: 768px) {
    margin-top: -1rem;
    width: 300px;
    flex-basis: 10%;
  }
`;

const Img = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  flex-direction: column;

  h1 {
    font-size: 2.5rem;
    color: #ffffff;
  }

  ${CardTree}:nth-child(1) & {
    background: #4397c3;
  }

  ${CardTree}:nth-child(2) & {
    background: #f09192;
  }

  ${CardTree}:nth-child(3) & {
    background: #8b8ee8;
  }

  ${CardTree}:nth-child(4) & {
    background: #5cc9aa;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 8rem;
`;

const ButtonCreate = styled.button`
  z-index: 9999;
  padding: 15px 45px;
  border-radius: 8px;
  text-transform: uppercase;
  border: none;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  margin-top: 20px;
  background-color: #174F68;
  width: 100%;
  max-width: 415px;
  color: #fff;
  cursor: pointer;

  & > a {
    font-family: Nunito;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }

  @media (max-width: 478px) {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 14px !important;
    width: 90%;
  }

  a {
    color: #FBF9ED !important;
  }
`;