import Link from "next/link";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface HamburgerIconProps {
  open: boolean;
}

const HeaderContainer = styled.header<{ scrolled: boolean }>`
  padding: 0.7rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #FBF9ED;

  width: ${({ scrolled }) => (scrolled ? "auto" : "140%")};
  margin: ${({ scrolled }) => (scrolled ? "0" : "20px auto")}; 

  box-shadow: ${({ scrolled }) => (scrolled ? "0 2px 10px rgba(0, 0, 0, 0.1)" : "none")};
  border-top-left-radius: ${({ scrolled }) => (scrolled ? "0" : "80px")};
  border-top-right-radius: ${({ scrolled }) => (scrolled ? "0" : "80px")};
  border-bottom-right-radius: ${({ scrolled }) => (scrolled ? "0" : "80px")};
  border-bottom-left-radius: ${({ scrolled }) => (scrolled ? "0" : "80px")};
  margin-top: ${({ scrolled }) => (scrolled ? "0" : "20px")}; 
  transition: all 0.3s ease;
  position: ${({ scrolled }) => (scrolled ? "fixed" : "fixed")};
  top: 0;
  left: 300;
  z-index: 999;

  img {

    transition: transform 0.3s ease; 

    :hover {
      transform: scale(1.5); /* Aumenta a escala em 20% no hover */
    }
  }

  @media (min-width: 768px) {
   width: ${({ scrolled }) => (scrolled ? "100%" : "85%")};
  }

  @media (max-width: 768px) {
    width: ${({ scrolled }) => (scrolled ? "100%" : "95%")};


  }

  @media (min-width: 1268px) { //
    width: ${({ scrolled }) => (scrolled ? "100%" : "70%")};
   }

   @media (min-width: 1668px) {
    width: ${({ scrolled }) => (scrolled ? "100%" : "70%")};
   }

   @media (min-width: 1768px) {
    width: ${({ scrolled }) => (scrolled ? "100%" : "70%")};
   }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
`;

const Logo = styled.div`
  img {
    max-width: 115px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: opacity 0.4s;

  :hover {
    opacity: 0.8;
  }

  background: #174F68;
  width: 100px;
  height: 33px;
  border-radius: 10px;
  font-weight: 800;

  text-decoration: none;
  font-size: 1.7rem;
  color: #fff;
`;

const Nav = styled.nav<HamburgerIconProps>`
  display: flex;
  gap: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 40px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    height: 45vh;
    width: 70vw;
    background-color: #FBF9ED;
    padding: 1rem;
    opacity: ${({ open }: HamburgerIconProps) => (open ? "1" : "0")};
    visibility: ${({ open }: HamburgerIconProps) => (open ? "visible" : "hidden")};
    transition: opacity 0.3s ease, visibility 0.3s ease;

    a {
      display: block;
      text-align: right;
    }
  }
`;

const NavItem = styled.a`
  color: #174F68;
  text-decoration: none;
  font-size: 1.5rem;

  transition: opacity 0.4s;

  :hover {
    opacity: 0.5;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HamburgerIcon = styled.div<HamburgerIconProps>`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 19px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }

  span {
    width: 100%;
    height: 2px;
    background-color: #333333;
    transition: transform 0.3s ease;

    &:first-child {
      transform: ${({ open }: HamburgerIconProps) => (open ? "rotate(45deg) translate(7px, 5px)" : "none")};
    }

    &:nth-child(2) {
      opacity: ${({ open }: HamburgerIconProps) => (open ? "0" : "1")};
    }

    &:last-child {
      transform: ${({ open }: HamburgerIconProps) => (open ? "rotate(-45deg) translate(7px, -5px)" : "none")};
    }
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    const checkIsMobile = () => {
      return window.matchMedia('(max-width: 768px)').matches;
    };

    setIsMobile(checkIsMobile());

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleHamburgerClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper>
      <HeaderContainer scrolled={scrolled}>
            <a href="/explore">
              <img  src="/search.svg" alt="search" />
            </a>

            <Nav open={isOpen}>
                {isMobile ? (
                  <>
                    <NavItem href="/contact">Contato</NavItem>
                    <NavItem href="/explore">Campanhas</NavItem> 
                    <Link href="/login" passHref>
                        <Button style={{ cursor: 'pointer' }}>Entrar</Button>
                    </Link>
                  </>
                ) : (
                  <>
                  <NavItem href="/contact">Contato</NavItem>
                  <Logo><img src="/Logo.svg" alt="" /></Logo>
                  <NavItem href="/explore">Campanhas</NavItem> 
                  </>
                )}
            </Nav>
            
            {isMobile ? (
              <Logo><img src="/Logo.svg" alt="" /></Logo>
            ) : (
              <Link href="/login" passHref>
                <Button style={{ cursor: 'pointer' }}>Entrar</Button>
              </Link>
            )}

            <HamburgerIcon open={isOpen} onClick={handleHamburgerClick}>
              <span></span>
              <span></span>
              <span></span>
            </HamburgerIcon> 
      </HeaderContainer>
    </Wrapper>
  );
};

export default Header;