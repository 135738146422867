import styled from 'styled-components';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import Container from 'components/Container';
import HeroIllustration from 'components/HeroIllustation';
import OverTitle from 'components/OverTitle';
import { media } from 'utils/media';

export default function Hero() {
  return (
    <HeroWrapper>
        <ImageContainer>
          <img src="./help.png" alt="friends" />
        </ImageContainer>
      <Contents>
        <CustomOverTitle>Faça a sua vaquinha</CustomOverTitle>
        <Heading>Quem ajuda uma mãe, ajuda toda uma família! </Heading>
        <Description>
        Pensando nisso, a Nossa Vaquinha criou uma plataforma de doação 
        com <strong>TAXA ZERO PARA MÃES</strong> criarem suas campanhas, seja por uma causa emergencial, seja para dar mais conforto e condições para seus filhos.
        </Description>
      
      </Contents>
    </HeroWrapper>
  );
}

const HeroWrapper = styled(Container)`
  display: flex;
  padding-top: 1rem;
  margin-bottom: 8rem;
  justify-content: center;
  align-items:center;

  ${media('<=desktop')} {
    padding-top: 1rem;
    flex-direction: column;
    align-items: center;
  }
`;

const Contents = styled.div`
  flex: 1;
  max-width: 70rem;

  ${media('<=desktop')} {
    max-width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 100%;

  img {
    width: 100%;
    max-width: 100%;
  }

  svg {
    max-width: 45rem;
  }

${media('<=desktop')} {
  margin-top: 2rem;
  justify-content: center;
  svg {
    max-width: 80%;
  }
}
`;

const Description = styled.p`
  font-size: 1.8rem;
  opacity: 0.8;
  line-height: 1.6;
  color: #174F68;

  ${media('<=desktop')} {
    font-size: 1.5rem;
  }
`;

const CustomOverTitle = styled(OverTitle)`
  margin-bottom: 2rem;
  color: #174F68;
`;

const Heading = styled.h1`
  color: #174F68;
  font-size:  5.2rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2rem;
  letter-spacing: -0.03em;

  ${media('<=tablet')} {
    font-size: 4.6rem;
    margin-bottom: 2rem;
  }
`;
