import { InferGetStaticPropsType } from 'next';
import styled from 'styled-components';
import { SEO } from 'components/Seo';
import { EnvVars } from 'env';
import HomePageDefault from 'views/HomePage/HomePageDefault';

export default function Homepage({}: InferGetStaticPropsType<typeof getStaticProps>) {

  return (
    <>
      <SEO
        title={`${EnvVars.SITE_NAME} - Presentes para uma nova vida`}
        description="Vaquinha."
        excludeTitleSuffix
        image={`${EnvVars.URL}/logo.png`}
      />
      <WhiteBackgroundContainer>
        <HomePageDefault />
      </WhiteBackgroundContainer>
      
    </>
  );
}

const WhiteBackgroundContainer = styled.div`
  background-color: #FBF9ED;
  font-display: swap;
`;

export async function getStaticProps() {
  return {
    props: {},
  };
}
