import React, { useState } from 'react';
import styled from 'styled-components';
import Accordion from 'components/Accordion';
import { questions } from 'utils/commomQuestions';

export default function FaqSection() {
  const [showQuestions, setShowQuestions] = useState(5);
  const handleLoadMore = () => {
    setShowQuestions(showQuestions + 5);
    };
  
  const handleLoadLess = () => {
    setShowQuestions(12);
  };

return (
<Wrapper>
  {questions.slice(0, showQuestions).map(question => (
    <ItemFaq key={question.id} title={question.text}>
      <span dangerouslySetInnerHTML={{ __html: question.description ? question.description : '' }} />
    </ItemFaq>
  ))}
  {showQuestions > 30 && 
    <InfoMessage>
    <strong>Caso não tenha encontrado aqui a resposta </strong>
    adequada para a sua pergunta, entre em contato conosco, através da aba “Contato” que fica no menu superior do site ou através do nosso e-mail contato@nossavaquinha.com.br.<br/><br/>
    Descreva seu problema e sua pergunta. Será um prazer te ajudar!<br/><br/>
    Atenciosamente,<br/><br/>
    ~Equipe Nossa vaquinha.
    </InfoMessage>
  }
  <ButtonLoadList onClick={showQuestions < 30 ? handleLoadMore : handleLoadLess}>
    {showQuestions < 30 ? "Ver mais perguntas" : "Ver menos"}
  </ButtonLoadList>
</Wrapper>
);
}

const Wrapper = styled.div`
  margin-top: 30px;
  width: 80%;

  & > *:not(:first-child) {
   margin-top: 2rem;
  }
  
  div {
    color: #222222;
    max-width: 1300px !important;

    h3 {
      border-bottom: 1px solid #fff;
    }
    
    span {
      width: 100%;
      max-width: 1300px ;
    }
  }
  `;

  const ItemFaq = styled(Accordion)`
  background-color: transparent !important;
  background: transparent;
  color: #fff;
`;

const ButtonLoadList = styled.button`
  padding: 15px 15px;
  background: transparent; 
  color: #174F68;
  cursor: pointer;
  font-size: 2rem;
  border: none;
  text-align: center;
`;

const InfoMessage = styled.div`
  font-size: 18px;
  margin-left: 1.5rem;
`;