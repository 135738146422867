import React, {  useEffect, useState } from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 12%;
  width: 100%;
  background-color: #FBF9ED;
  color: #fff;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
  
  a {
    text-decoration:none; 
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.show {
    transform: translateY(0);
  }

  z-index: 99999;
`;

const Button = styled.button`
  background-color: #174F68;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  margin-top: 25px;
`;

const ScrollToTopButton = () => {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const footer = document.getElementById('footer');

      if (footer && scrollTop > window.innerHeight) {
        footer.classList.add('show');
        setShowFooter(true);
      } else if (footer) {
        footer.classList.remove('show');
        setShowFooter(false);
      }
    };

    const checkIsMobile = () => {
      return window.matchMedia('(max-width: 768px)').matches;
    };

    if (checkIsMobile()) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <FooterContainer id="footer" className={showFooter ? 'show' : ''}>
      <div>
        <a href="/login">
          <Button>Criar uma campanha</Button>
        </a>
      </div>
    </FooterContainer>
  );
};

export default ScrollToTopButton;
