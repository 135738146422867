import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const PigImage = styled.img`
  position: absolute;
  top: 0;
  left: 40%;
  transform: translateX(-50%);
  width: 250px; 

  @media (max-width: 768px) {
    left: 47%;
    width: 210px; 
  }
`;

interface CoinsImageProps {
  scrolled: boolean;
}

const CoinsImage = styled.img<CoinsImageProps>`
  position: absolute;
  top: ${({ scrolled }) => (scrolled ? '50px' : '-60px')};
  left: 41%;
  transform: translateX(-50%);
  width: 80px; 

  transition: top 0.5s ease, opacity 0.5s ease-in-out; 
  opacity: ${({ scrolled }) => (scrolled ? '0' : '1')}; 

  @media (max-width: 768px) {
    left: 53%;
    width: 70px;
  }
`;

const TextLogoImage = styled.img`
  width: 380px; 
  position: absolute;
  left: 50%;
  top: 0;

  @media (max-width: 768px) {
    width: 250px; 
    position: relative; 
    left: 40px;
    transform: none;
    top: 180px; 
  }
`;


const PiggyBank = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) { // ajuste este valor conforme necessário para determinar quando a animação deve acontecer
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ position: 'relative', height: '100px' }}>
      <PigImage src="/Porco1.png" alt="Porquinho" />
      <CoinsImage scrolled={scrolled} src="/moeda.png" alt="Moedas" />
      <TextLogoImage src="/text-logo.png" alt="Outra Imagem" />
    </div>
  );
};

export default PiggyBank;
